.chat {
    margin-top: auto;
    margin-bottom: auto;

    .chat-card {
        height: 800px;
        border-radius: 15px !important;
        background: #F2F2F2;

        & > .card-body {
            padding: 0 !important;
            display: flex;
            flex-direction: column;
        }
    }

    .chat-body {
        overflow-y: auto;
        padding: 1.25rem;
        flex: 1;
    }

    .card-header {
        border-radius: 15px 15px 0 0 !important;
    }

    .card-footer {
        border-radius: 0 0 15px 15px !important;
        border-top: 0 !important;

        .fas {
            font-size: 1.5em;
        }
    }
    .chat-header {
        .action-button {
            font-size: 20px;
        }

        .user-info {
            margin: 0 15px;
            overflow: hidden;

            span {
                font-size: 20px;
            }

            div {
                font-size: 12px;
            }
        }

        .images {
            display: flex;
            align-items: center;
        }

        .avatar {
            height: 70px;
            width: 70px;
            border: 1.5px solid #f5f6fa;
        }

        .book-cover {
            height: 70px;
            margin-left: -10px;
        }
    }

    .container {
        align-content: center;
    }

    .search {
        .form-control {
            background-color: #353b48 !important;
            border-radius: 30px 0 0 30px !important;
            border: 0 !important;
            color: white;
        }

        .form-control:focus {
            box-shadow: none !important;
            outline: 0px !important;
        }

        .search-button {
            border-radius: 0 30px 30px 0 !important;
            background-color: #353b48 !important;
            border: 0 !important;
            cursor: pointer;
        }
    }

    .message-input {
        resize: none;
        background-color: #F6F6F6  !important;
        border: 0 !important;
        height: 60px !important;
        overflow-y: auto;
    }

    .message-input:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }

    .btn-attach {
        border-radius: 15px 0 0 15px !important;
        background-color: #F6F6F6  !important;
        border: 0 !important;
        cursor: pointer;
    }

    .btn-send {
        border-radius: 0 15px 15px 0 !important;
        background-color: #F6F6F6  !important;
        border: 0 !important;
        cursor: pointer;
    }

    .btn-emoji {
        background-color: #F6F6F6  !important;
        border: 0 !important;
        cursor: pointer;
    }

    .contacts-body {
        padding: 0 !important;
        overflow-y: auto;

        .contacts {
            list-style: none;
            padding: 0;

            .contact {
                display: flex;
                cursor: pointer;
                width: 100% !important;

                .images {
                    display: flex;
                    align-items: center;
                    padding-left: 25px;

                    .book-cover {
                        height: 60px;
                        margin-left: -10px;
                    }

                    .avatar {
                        height: 60px;
                        width: 60px;
                        border: 1.5px solid #f5f6fa;
                    }
                }

                .user-info {
                    //margin-left: 15px;

                    .book-name, .last-message {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        -webkit-box-orient: vertical;
                    }

                    .last-message {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

    // Attenzione a dare gli stili a classi cosi generiche!!!
    // Potresti creare dei piccoli disastri in giro per il sito senza accorgertene finchè non ci passi!
    // .active{
    //     background-color: rgba(0,0,0,0.3);
    // }

    .img_cont {
        position: relative;
        height: 70px;
        width: 70px;
    }

    .online_icon {
        position: absolute;
        height: 15px;
        width: 15px;
        background-color: #4cd137;
        border-radius: 50%;
        bottom: 0.2em;
        right: 0.4em;
        border: 1.5px solid white;
    }

    .offline {
        background-color: #c23616 !important;
    }

    .message-row {
        display: flex;
        justify-content: start;
        margin-bottom: 1.5rem;

        &.send {
            flex-direction: row-reverse;
            justify-content: end;
        }

        .avatar-container {
            height: 40px;
            width: 40px;

            .avatar {
                height: 40px;
                width: 40px;
                border: 1.5px solid #f5f6fa;

            }
        }

        .message {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
        }

        &.send .message {
            align-items: flex-end;
            margin-right: 10px;
        }

        .content {
            margin-top: auto;
            margin-bottom: auto;
            border-radius: 25px;
            background-color: #dfe0eb;
            padding: 10px;
            position: relative;

            .book-cover {
                height: 100px;
            }
        }

        &.send .content {
            background-color: #d0d5c7 !important;
        }

        .content.system {
            background-color: #ffffff !important;
            padding: 1rem 3rem;
        }
    }

    .video_cam {
        margin-left: 50px;
        margin-top: 5px;
    }

    .video_cam span {
        color: white;
        font-size: 20px;
        cursor: pointer;
        margin-right: 20px;
    }

    .time {
        //color: rgba(255, 255, 255, 0.5);
        font-size: 10px;
    }

    .action_menu {
        z-index: 1;
        position: absolute;
        padding: 15px 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border-radius: 15px;
        top: 30px;
        right: 15px;
        display: none;
    }

    .action_menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .action_menu ul li {
        width: 100%;
        padding: 10px 15px;
        margin-bottom: 5px;
    }

    .action_menu ul li i {
        padding-right: 10px;

    }

    .action_menu ul li:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.2);
    }

    @media(max-width: 576px) {
        .contacts_card {
            margin-bottom: 15px !important;
        }
    }

    .btn-chat-confirm {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border: 0;
    }

    .chat-border{
        border: none;
    }

}

.popover {
    max-width: fit-content !important;
}

emoji-picker {
    --outline-size: 0px;
    --border-size: 0px;
}
