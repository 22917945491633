@font-face {
  font-family:'Poppins';
  src:url('../fonts/poppins-light.woff2') format('woff2'),
  url('../fonts/poppins-light.woff') format('woff'),
  url('../fonts/poppins-light.eot') format('opentype');
  font-display:swap;
  font-style:normal;
  font-weight:300;
}
@font-face {
  font-family:'Poppins';
  src:url('../fonts/poppins-regular.woff2') format('woff2'),
  url('../fonts/poppins-regular.woff') format('woff'),
  url('../fonts/poppins-regular.eot') format('opentype');
  font-display:swap;
  font-style:normal;
  font-weight:400;
}
@font-face {
  font-family:'Poppins';
  src:url('../fonts/poppins-bold.woff2') format('woff2'),
  url('../fonts/poppins-bold.woff') format('woff'),
  url('../fonts/poppins-bold.eot') format('opentype');
  font-display:swap;
  font-style:normal;
  font-weight:700;
}
