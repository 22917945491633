.searchbar{
    margin-bottom: auto;
    margin-top: auto;
    //height: 40px;
    background-color: #f7f7f7;
    border-radius: 30px;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.search_input{
    border: 0;
    outline: 0;
    background: none;
    //width: 0;
    caret-color:transparent;
    //transition: width 0.4s linear;
    padding:0 10px;
}

.search_icon{
    height: 20px;
    width: 20px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-decoration:none;
    color: $body-color;
    &:hover {
      text-decoration:none;
    }
}

.searchbar:hover > .search_input{
    //padding: 0 10px;
    //width: 180px;
    caret-color:$body-color;
    //transition: width 0.4s linear;
}

.searchbar:hover > .search_icon{
    //background: white;
    color: $body-color;
}
