// Body
$body-bg: #ffffff;

// Colors
$acciopurple: #565675;
$acciolilla: #ABA9EE;
$acciolillalight: #e5e7ff;
$acciogreen: #AEBEA8;
$acciodarkgreen: #808E8A;
$acciobrown: #6E6371;
$acciored: #A56671;
$acciobrick: #9D8E95;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #E6AD26;
$green: #9EC83D;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$black: #000;

$primary:       #3B4453;
$secondary:     $acciolilla;
$tertiary:      $acciolillalight;
$quaternary:    $acciogreen;
$quinary:       $acciored;
$senary:        $acciodarkgreen;
$septenary:     $acciobrick;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $acciored;
$light:         #F6F6F6;
$extralight:    #FAFAFA;
$dark:          #3B4453;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "tertiary":   $tertiary,
    "quaternary": $quaternary,
    "quinary":    $quinary,
    "senary":     $senary,
    "septenary":  $septenary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "extralight": $extralight,
    "dark":       $dark
  ),
  $theme-colors
);

// Typography
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.9rem;
$body-color: #3B4453;
$headings-color: #3B4453;
$line-height-base: 1.6;
$headings-line-height: 1.5;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;
$font-size-sm: $font-size-base * .7;
$enable-responsive-font-sizes: true;

// Spacing
$spacer: 1rem;

// Navbar
$navbar-padding-y: $spacer;
$nav-link-padding-y: 0.3rem;
$navbar-nav-link-padding-x: 1rem;
$navbar-light-color: rgba($black, .7);
$navbar-light-hover-color: rgba($black, .9);
$navbar-light-active-color: rgba($black, 1);

// Input
// $input-btn-focus-width: 0rem;
// $input-focus-border-color: none;

// Input/Button
$input-btn-padding-y-sm: 0.45rem;
$input-btn-padding-x-sm: 1.3rem;
$input-btn-font-size-sm: .6rem;

$input-btn-padding-y: .6rem;
$input-btn-padding-x: 1.6rem;
$input-btn-font-size: .75rem;

$input-btn-padding-y-lg: .8rem;
$input-btn-padding-x-lg: 2.4rem;
$input-btn-font-size-lg: $font-size-base * 1.15;

$input-btn-border-width: 2px;
$btn-font-weight: $font-weight-bold;

$input-bg: #ffffff;
$input-group-addon-bg: $input-bg;

// Border Radius
$border-radius-sm: 1rem;
$border-radius: 1.5rem;
$border-radius-lg: 2rem;

// Modals
$modal-content-border-radius: 1rem;

// Breakpoint
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px,
  xxl: 1700px  // Breakpoint custom per monitor 4k
);

// Container MaxWidth
$container-max-widths: (
  sm: 540px, // Valore di default: 540px
  md: 720px, // Valore di default: 720px
  lg: 980px, // Valore di default: 960px
  xl: 1140px, // Valore di default: 1140px
  xxl: 1500px
);
