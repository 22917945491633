body {
    margin: 0;
}

nav {
  .navbar-brand {
    img {
      width: 240px;
      max-width: 240px;
      @include media-breakpoint-down(md) {
        width: 200px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      border: none;
      border-radius: 0px;
      .dropdown-item {
        padding: 0.25rem 1.2rem;
        &:hover {
          background-color: white;
        }
      }
    }
  }
}

.avatar-top-right {
  width: 60px;
}

.shadow-lg-hover {
  -webkit-transition: all 2.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 2.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover {
    box-shadow: $box-shadow-lg;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}





.book_in_commerce{
    background-color: #a9c1ac;
}

.sidebar-collapse-btn {
  .fas {
    -webkit-transition: all .7s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .7s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &.collapsed {
    .fas {
      transform: rotate(180deg);
    }
  }
}


.direct-chat-messages{
    height: 100%;
}

.direct-chat-primary .direct-chat-text{
    min-height: 40px;
}

.direct-chat-primary .fas{
    color: #4fc3f7;
}

#pills-tab {
  .nav-link {
    &.active {
      color: $dark !important;
      background-color: transparent;
    }
  }
}

#book_library_menu {
    .link {
        color: $dark;
        background-color: transparent;
        &.active {
            border-bottom: solid 3px;
        }
        &:hover {
            text-decoration: none !important;
        }
    }
}

.accio-search {
  background-color: $tertiary;
  border-color: $tertiary;
  box-shadow: 6px 6px 15px rgba($secondary, .4);
  border-radius: 25px;
  border-width: 2px;
  &:focus {
    border-color: $secondary;
    box-shadow: 6px 6px 15px rgba($secondary, .4);
  }
}

.profile-img-overlay {
  position: relative;
  z-index: -1;
  .profile-bg {
    //background-image: url('resources/images/backgrounds/profile-bg.jpg');
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 1;
  }
  .profile-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    z-index: 2;
  }
}

.border-1px {
    border: 1px solid !important;
    &.border-tertiary {
        border-color: $tertiary !important;
    }
}

.border-2px {
    border: 2px solid !important;
    &.border-tertiary {
        border-color: $tertiary !important;
    }
}

.border-3px {
  border: 3px solid !important;
  &.border-tertiary {
    border-color: $tertiary !important;
  }
}

.border-4px {
    border: 4px solid !important;
    &.border-tertiary {
        border-color: $tertiary !important;
    }
}


// Follower preview card
.follower-preview-card {
  .follower-preview-card-header {
    height: 120px;
    .follower-preview-card-header-overlay {
      padding: 20px;
      height: 100%;
      background-color: rgba(105, 85, 85, 0.65);
    }
  }
  .follower-preview-card-img-wrapper {
    margin-top: -45px;
    img {
      border: 3px solid #fff;
    }
  }
}



.bg-yellow {
    background-color: #f39c12 !important;
}
.bg-blue {
    background-color: #0073b7 !important;
}
.bg-aqua {
    background-color: #00c0ef !important;
}
.bg-green {
    background-color: #00a65a !important;
}
.bg-red {
    background-color: #dd4b39 !important;
}

.toggle_radio{
    position: relative;
    background: rgb(0 0 0 / 0.50);
    margin: 4px auto;
    overflow: hidden;
    padding: 0 !important;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    position: relative;
    height: 26px;
}
.toggle_radio > * {
    float: left;
}
.toggle_radio input[type=radio]{
    display: none;
    /*position: fixed;*/
}
.toggle_radio label{
    font: 90%/1.618 "Source Sans Pro";
    color: rgba(255, 255, 255, 0.9);
    z-index: 0;
    display: block;
    width: 50%;
    height: 20px;
    margin: 3px 0;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1;
    /*background: rgba(0,0,0,.1);*/
    text-align: center;
    /*margin: 0 2px;*/
    /*background: blue;*/ /*make it blue*/
}
.toggle_option_slider{
    /*display: none;*/
    /*background: red;*/
    width: 49%;
    height: 20px;
    position: absolute;
    top: 3px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease;
}

#first_toggle:checked ~ .toggle_option_slider{
    background: rgba(255,255,255,.3);
    left: 3px;
}
#second_toggle:checked ~ .toggle_option_slider{
    background: rgba(255,255,255,.3);
    left: 50%;
}

#first_toggle_library:checked ~ .toggle_option_slider{
    background: rgba(255,255,255,.3);
    left: 3px;
}
#second_toggle_library:checked ~ .toggle_option_slider{
    background: rgba(255,255,255,.3);
    left: 50%;
}

.custom-file-label{
    left: 15px;
    right: 15px;
}

// -------------------------------------------- 12 Novembre 2020

// Responsive square aspect ratio for all books
.responsive-aspect-ratio {
  position: relative;
  height: 0;
  overflow: hidden;
  &.square-aspect-ratio {
    padding-top: 100%;
  }
  &.vertical-aspect-ratio {
    padding-top: 120%;
  }
  img {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
  }
}

// Utilities
.object-fit-cover {
  object-fit: cover;
}

// Genre Card
.genre-card {
  .genre-card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    &.overlay-grey {
      background: rgb(59,68,83);
      background: -moz-linear-gradient(0deg, rgba(59,68,83,1) 0%, rgba(59,68,83,0.8) 40%, rgba(59,68,83,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(59,68,83,1) 0%, rgba(59,68,83,0.8) 40%, rgba(59,68,83,0) 100%);
      background: linear-gradient(0deg, rgba(59,68,83,1) 0%, rgba(59,68,83,0.8) 40%, rgba(59,68,83,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3B4453",endColorstr="#3B4453",GradientType=1);
    }
    &.overlay-lime {
      background: rgb(200,217,162);
      background: -moz-linear-gradient(0deg, #aebea8 0%, #aebea8 20%, rgba(200, 217, 162, 0) 100%);
      background: -webkit-linear-gradient(0deg, #aebea8 0%, #aebea8 20%, rgba(200, 217, 162, 0) 100%);
      background: linear-gradient(0deg, #aebea8 0%, #aebea8 20%, rgba(200, 217, 162, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c8d9a2",endColorstr="#c8d9a2",GradientType=1);
    }
    &.overlay-orange {
      background: rgb(251,198,144);
      background: -moz-linear-gradient(0deg, rgba(251,198,144,1) 0%, rgba(251,198,144,0.8) 40%, rgba(251,198,144,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(251,198,144,1) 0%, rgba(251,198,144,0.8) 40%, rgba(251,198,144,0) 100%);
      background: linear-gradient(0deg, rgba(251,198,144,1) 0%, rgba(251,198,144,0.8) 40%, rgba(251,198,144,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FBC690",endColorstr="#FBC690",GradientType=1);
    }
    &.overlay-yellow {
      background: rgb(255,220,133);
      background: -moz-linear-gradient(0deg, rgba(255,220,133,1) 0%, rgba(255,220,133,0.8) 40%, rgba(255,220,133,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(255,220,133,1) 0%, rgba(255,220,133,0.8) 40%, rgba(255,220,133,0) 100%);
      background: linear-gradient(0deg, rgba(255,220,133,1) 0%, rgba(255,220,133,0.8) 40%, rgba(255,220,133,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFDC85",endColorstr="#FFDC85",GradientType=1);
    }
  }
  .genre-card-content {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
}

// Style for white Buttons
.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: $primary;
  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: #ffffff;
  }
}

// Profile image style for differente user typology (Author, Simple user, Library)
.profile-image-border-gradient {
  border-radius: 100%;
  padding: 4px;
  .profile-image {
    border: 3px solid white;
  }
  .profile-image-icon {
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 9;
    width: 23%;
    min-width: 30px;
  }
  &.simple-user-border-gradient {
    background: rgb(86,86,118);
    background: -moz-linear-gradient(0deg, rgba(86,86,118,1) 0%, rgba(167,101,112,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(86,86,118,1) 0%, rgba(167,101,112,1) 100%);
    background: linear-gradient(0deg, rgba(86,86,118,1) 0%, rgba(167,101,112,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#565676",endColorstr="#a76570",GradientType=1);
  }
  &.library-border-gradient {
    background: rgb(122,145,141);
    background: -moz-linear-gradient(180deg, rgba(122,145,141,1) 0%, rgba(220,238,209,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(122,145,141,1) 0%, rgba(220,238,209,1) 100%);
    background: linear-gradient(180deg, rgba(122,145,141,1) 0%, rgba(220,238,209,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7a918d",endColorstr="#dceed1",GradientType=1);
  }

  &.author-border-gradient {
    background: rgb(93,93,121);
    background: -moz-linear-gradient(0deg, rgba(93,93,121,1) 0%, rgba(186,185,241,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(93,93,121,1) 0%, rgba(186,185,241,1) 100%);
    background: linear-gradient(0deg, rgba(93,93,121,1) 0%, rgba(186,185,241,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d5d79",endColorstr="#bab9f1",GradientType=1);
  }
}

// Style for bottom bar on footer
.copyright-bar {
  background-color: #EBEBEA;
  p {
    color: lighten($body-color, 30%);
  }
}

// Hero sections
.hero {
  $hero-padding-y: 80px;
  padding-top: calc(#{$hero-padding-y} + 89.09px);
  padding-bottom: calc(#{$hero-padding-y} + 40px);
  // &.hero-user {
  //   padding-bottom: 0px;
  //   @include media-breakpoint-down(sm) {
  //     padding-bottom: 340px;
  //   }
  // }
  @include media-breakpoint-down(md) {
    padding-top: 120px;
  }
  &.hero-padding-sm {
    @include media-breakpoint-up(lg) {
      padding-top: 50px;
      padding-bottom: 20px;
    }
  }
  &.hero-wave {
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    &.hero-wave-E2D0D4 {
      background-color: #E2D0D4;
    }
    &.hero-wave-DFE0EB {
      background-color: #DFE0EB;
    }
    &.hero-wave-D0D5C7 {
      background-color: #D0D5C7;
    }
    &.hero-wave-CAC4CC {
      background-color: #CAC4CC;
    }
    &.hero-wave-C9DEDB {
      background-color: #C9DEDB;
    }
  }
  &.has-overlap-img {
    @include media-breakpoint-down(md) {
      & + * {
        padding-top: 60px;
      }
    }
    [class*="col-lg-"] {
      @include media-breakpoint-down(lg) {
        .hero-illustration {
          margin-bottom: -60px;
        }
      }
    }
    [class*="col-md-"] {
      @include media-breakpoint-down(md) {
        .hero-illustration {
          margin-bottom: -60px;
        }
      }
    }

    &.has-overlap-img-with-text {
      [class*="col-lg-"] {
        @include media-breakpoint-up(lg) {
          .hero-illustration {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
          }
        }
      }
      [class*="col-md-"] {
        @include media-breakpoint-up(md) {
          .hero-illustration {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
          }
        }
      }
      [class*="col-lg-"] {
        @include media-breakpoint-down(lg) {
          .hero-illustration {
            margin-bottom: -220px;
          }
        }
      }
      [class*="col-md-"] {
        @include media-breakpoint-down(md) {
          .hero-illustration {
            margin-bottom: -220px;
          }
        }
      }
    }
  }
  .come-funziona-hero-right-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 600px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include media-breakpoint-up(lg) {
      background-position: center right;
    }
  }
  .sponsor-hero-bg {
    background-image: url("../images/sponsor-hero-bg.png");
    position: absolute;
    top: 0px;
    left: 0px;
  }
  &.vh-100 {
    min-height: 700px;
  }
}

.wave-top-white {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  transform: translateY(-95%);
  pointer-events: none !important;
}

.z-index-9 {
  z-index: 9;
}

// Author page
.author-negative-margin {
  margin-top: -130px;
}

// Come funziona
.come-funziona-icon {
  max-width: 75px;
}

// Acciobooks PRO
.pro-icon {
  max-width: 60px;
}
.selected-plain-icon {
  max-width: 50px;
  position: absolute;
  top: -15px;
  right: -15px;
}

.selected-crown {
  position: absolute;
  top: 10px;
  right: 10px;
  img {
    width: 35px;
  }
}

// Manifesto
.wave-top-quote {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  transform: translateY(-99.4%);
}
.wave-bottom-quote {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  transform: translateY(99.4%);
}
.quote-icon {
  max-width: 40px;
}

// Style for input placeholder with different background
input {
  &.bg-secondary {
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: white;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: white;
      opacity: 1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: white;
      opacity: 1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }

    &::placeholder { /* Most modern browsers support this now. */
      color: white;
    }
  }
}

.bg-quinary-10 {
  background-color: lighten($quinary, 40%);
}

// Style for navbar when is scrolled
nav {
  transition: all 0.4s ease-in-out;
  &.navbar-scrolled {
    background-color: white;
  }
  &.nav-open {
    background-color: white;
  }
}

// Book page
.book-hero {
  padding-top: 250px;
  padding-bottom: 250px;
}
.book-negative-margin {
  margin-top: -390px;
}

// Book Shipping page
.book-shipping-hero {
  padding-top: 180px;
  padding-bottom: 180px;
}
.book-shipping-negative-margin {
  margin-top: -210px;
}

// Dropdown icon animation
.dropdown {
  .dropdown-icon {
    transform: rotate(0deg);
    transform-origin: center;
    transition: transform 0.30s ease-in-out;
  }
  &.show {
    .dropdown-icon {
      transform: rotate(-180deg);
    }
  }
}
.collapse-btn {
  .dropdown-icon {
    transform-origin: center;
    transition: transform 0.30s ease-in-out;
  }
  &[aria-expanded="false"] {
    .dropdown-icon {
      transform: rotate(0deg);
    }
  }
  &[aria-expanded="true"] {
    .dropdown-icon {
      transform: rotate(-180deg);
    }
  }
}

.libro-mastro-table {
  .table {
    thead {
      background-color: $quaternary;
      color: #ffffff;
      th {
        text-align: center;
        border-color: white;
        i {
          display: none;
        }
      }
    }
    tbody {
      tr {
        background-color: white;
        td {
          text-align: center;
          vertical-align: middle !important;
          border-color: $primary;
        }
        >td {
          &:first-child {
            border-left: 0px !important;
          }
          &:last-child {
            border-right: 0px !important;
          }
        }
      }
    }
    tbody + tbody {
      border-top: 1px solid $primary;
    }
  }
}

.following-follower-thumb-group {
    .row {
        >a {
            .following-follower-thumb-user {
                img {
                    z-index: 1001;
                }
            }
            &:first-child {
                >.following-follower-thumb-user {
                    transform: translateX(10px) !important;
                    img {
                        z-index: 1002;
                    }
                }
            }
            &:nth-child(3) {
                >.following-follower-thumb-user {
                    transform: translateX(-10px);
                    img {
                        z-index: 1000;
                    }
                }
            }
        }
        >a {
            .following-follower-thumb-user {
                img {
                    z-index: 1001;
                }
            }
            &:first-child {
                >.following-follower-thumb-user {
                    transform: translateX(10px) !important;
                    img {
                        z-index: 1002;
                    }
                }
            }
            &:last-child {
                >.following-follower-thumb-user {
                    transform: translateX(-10px);
                    img {
                        z-index: 1000;
                    }
                }
            }
        }

    }
}
.following-follower-thumb-group {
    .row {
        >div {
            .following-follower-thumb-user {
                img {
                    z-index: 1001;
                }
            }
            &:first-child {
                >.following-follower-thumb-user {
                    transform: translateX(10px) !important;
                    img {
                        z-index: 1002;
                    }
                }
            }
            &:last-child {
                >.following-follower-thumb-user {
                    transform: translateX(-10px);
                    img {
                        z-index: 1000;
                    }
                }
            }
        }
        >a {
            .following-follower-thumb-user {
                img {
                    z-index: 1001;
                }
            }
            &:first-child {
                >.following-follower-thumb-user {
                    transform: translateX(10px) !important;
                    img {
                        z-index: 1002;
                    }
                }
            }
            &:last-child {
                >.following-follower-thumb-user {
                    transform: translateX(-10px);
                    img {
                        z-index: 1000;
                    }
                }
            }
        }

    }
}

input {
  &.borderless {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.no-hover-underline {
  &:hover {
    text-decoration: none !important;
  }
}

.letter-spacing-md {
  letter-spacing: 5px;
}

.error-page {
  height: 100vh;
  @include media-breakpoint-down(md) {
    height: 70vh;
  }
  .error-page-bg {
    background-image: url("../images/error-hero-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
  .error-page-content {
    height: 100vh;
    @include media-breakpoint-down(md) {
      height: 55vh;
    }
  }
}

.fake-btn {
  cursor: default !important;
  &.btn-primary {
    &:hover {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
  &.btn-secondary {
    &:hover {
      background-color: $secondary !important;
      border-color: $secondary !important;
    }
  }
  &.btn-tertiary {
    &:hover {
      background-color: $tertiary !important;
      border-color: $tertiary !important;
    }
  }
  &.btn-quaternary {
    &:hover {
        color: #ffffff !important;
      background-color: $quaternary !important;
      border-color: $quaternary !important;
    }
  }
  &.btn-quinary {
    &:hover {
      background-color: $quinary !important;
      border-color: $quinary !important;
    }
  }
  &.btn-senary {
    &:hover {
     color: #ffffff !important;
      background-color: $senary !important;
      border-color: $senary !important;
    }
  }
}

// Custom input group addon
  .custom-input-group {
    .input-group-prepend {
      button {
        border-right: 0px;
        border-color: $input-border-color;
        padding: 0.6rem 1rem;
        &:focus {
          box-shadow: 0 0 0 0 transparent !important;
        }
      }
    }
    input {
      border-left: 0px;
      padding-left: 1rem;
      &:focus {
        box-shadow: 0 0 0 0 transparent !important;
      }
    }
  }


/*  STRIPE  */
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.custom-checkbox-radio {
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .btn span.fa-check {
    opacity: 0;
  }
  .btn.active span.fa-check {
    opacity: 1;
  }
}

.rating {
    display: inline-flex;
    flex-direction: row-reverse;
}

.rating-0 {
    filter: grayscale(100%);
}

.rating > input {
    display: none;
}

.rating > label {
    cursor: pointer;
    width: 28px;
    height: 28px;
    margin-top: auto;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    transition: .3s;
}

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}


.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}
/*
.emoji-wrapper {
    width: 100%;
    text-align: center;
    height: 100px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.emoji-wrapper:before,
.emoji-wrapper:after{
    content: "";
    height: 15px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
}

.emoji-wrapper:before {
    top: 0;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,0) 100%);
}

.emoji-wrapper:after{
    bottom: 0;
    background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,0) 100%);
}

.emoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .3s;
}

.emoji > svg {
    margin: 15px 0;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
}

#rating-1:checked ~ .emoji-wrapper > .emoji { transform: translateY(-100px); }
#rating-2:checked ~ .emoji-wrapper > .emoji { transform: translateY(-200px); }
#rating-3:checked ~ .emoji-wrapper > .emoji { transform: translateY(-300px); }
#rating-4:checked ~ .emoji-wrapper > .emoji { transform: translateY(-400px); }
#rating-5:checked ~ .emoji-wrapper > .emoji { transform: translateY(-500px); }
*/
.feedback {
    max-width: 360px;
    background-color: #fff;
    width: 100%;
    padding: 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 4px 30px rgba(0,0,0,.05);
}

.max-w-50-px {
  max-width: 50px;
}

.max-w-100-px {
    max-width: 100px;
}

.bg-E2D0D4 {
  background-color: #e2d0d4;
}
.bg-DFE0EB {
  background-color: #dfe0eb;
}
.bg-D0D5C7 {
  background-color: #d0d5c7;
}
.bg-CAC4CC {
  background-color: #cac4cc;
}
.bg-C9DEDB {
  background-color: #c9dedb;
}

.py-150-px {
  padding-top: 150px;
  padding-bottom: 150px;
}

.tooltip-arrow,
.tooltip > .tooltip-inner {
    background-color: #DFE0EB;
    color: #3b4453;
    border: none;
    padding: 15px;
    font-size: 12px;
    width: 300%;
}

.free-btn-comparison {
  top: 0;
  right: 20px;
  transform: translateY(-50%);
}

.btn-book-card {
  font-size: 0.6rem;
  padding-left: .8rem;
  padding-right: .8rem;
}

.select-input {
  position: relative;
  select{
    /* remove the original arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    position:relative;
}
  .fa {
    position: absolute;
    pointer-events: none;
    top: 15px;
    right: 20px;
  }
}

.dropdown-menu.notification {
    min-width: 520% !important;
}

.home-cta-img {
  margin-top: -120px;
  @include media-breakpoint-up(lg) {
    margin-bottom: -120px;
  }
}

.file-drop-area {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 100%;
    transition: .2s
}

.choose-file-button {
    flex-shrink: 0;
    font-size: 12px;
    text-transform: uppercase
}

.file-message {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    widows: 100%;
    cursor: pointer;
    opacity: 0
}

.border-search{
    border-color: #EBEBEA;
}

.subscription-table {
  th, td {
    border-width: 2px !important;
  }
}

.label-top-overlapped {
  top: 0px;
  transform: translateY(-50%);
}

.btn.disabled {
    cursor: not-allowed;
}

.free-user-restriction-section {
  max-height: 55vh;
  overflow: hidden;
  .free-user-restriction-overlay {
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    z-index:99;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0.7) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0.7) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    @include media-breakpoint-down(md) {
      background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0.7) 100%);
      background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0.7) 100%);
      background: linear-gradient(0deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0.7) 100%);
    }
  }
}

.btn-outline-quaternary:not(:disabled):not(.disabled):active,
.btn-outline-quaternary:not(:disabled):not(.disabled).active,
.show > .btn-outline-quaternary.dropdown-toggle {
    color: #ffffff !important;
    background-color: $quaternary;
    border-color: $quaternary;
}

.btn-outline-quaternary:hover {
    color: #ffffff !important;
    background-color: $quaternary;
    border-color: $quaternary;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff !important;
    background-color: $secondary;
    border-color: $secondary;
}

.btn-outline-secondary:hover {
    color: #ffffff !important;
    background-color: $secondary;
    border-color: $secondary;
}

.user-img{
    max-width: 240px;
}

.dropzone{
    min-height: 210px !important;
}

.check-img {
    max-width: 60% !important;
}

.footer-logo-img{
    margin-left: -16px;
}

@media (max-width: 990px){
    .footer-logo-img {
        max-width: 65%;
        margin-left: 0;
    }
}

.book-content-wrapper{
    min-height: 75px;
}

.input-group-append:focus{
    outline: 0 !important;
}

.send_btn:focus{
    outline: 0 !important;
}

@media (min-width: 1200px) and (max-width: 1690px){
    .ml-42{
        margin-left: 42px !important;
    }
}

.pr-4-5 {
    padding-right: 2.5rem !important;
}

.card-img-bottom-overlapped {
    position: absolute;
    bottom: -100px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    max-width: 400px;
  @include media-breakpoint-down(lg) {
    bottom: -70px;
  }
}

@media (max-width: 990px) {
    .py-mobile-0 {
        padding-top : 0;
        padding-bottom : 0;
    }
    .pb-mobile-0 {
        padding-bottom : 0;
    }
    .px-mobile-0 {
        padding-left : 0;
        padding-right : 0;
    }
}

.carousel-indicators{
    bottom: -30%;
    li{
        background-color: $primary;
        opacity: 0.2;
    }
}

.bottom_-20{
    bottom: -20% !important;
}

.carousel-control-prev-icon{
    background-image: url("../images/left-chevron.png");
}
.carousel-control-prev{
    width: 2%;
}
.carousel-control-next-icon{
    background-image: url("../images/right-chevron.png");
}

.carousel-control-next{
    width: 2%;
}

.remove-black-line{
    outline: 4px solid #fff;
    outline-offset: -2px;
}

.custom-text-muted{
    color: #b8bec3 !important;
}

.btn-white:hover{
    background-color: #ffffff;
}

.dropdown-item.active, .dropdown-item:active {
    color: #16181b;
    text-decoration: none;
    background-color: transparent;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    border-radius: 0px;
    text-decoration: none;
    background-color: transparent;
}


.timer {
    font-family: sans-serif;
    display: inline-block;
    text-align: center;
}
.timer div {
    padding: 10px;
    border-radius: 3px;
    display: inline-block;
    font-size: 26px;
    font-weight: 400;
    width: 65px;
}
.timer .smalltext {
    color: #888888;
    font-size: 12px;
    font-weight: 500;
    display: block;
    padding: 0;
    width: auto;
}
.timer #time-up {
    margin: 8px 0 0;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    color: #E2D0D4;
    font-weight: 500;
    letter-spacing: 1px;
}
.no-hover-underline:hover {
    text-decoration: none !important;
}
@media (max-width: 768px) {
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }
}

.ad {
    background-color: white;
}

.row > .column {
    padding: 0 8px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Create four equal columns that floats next to eachother */
.column {
    float: left;
    width: 25%;
}

/* The Modal (background) */
.gallery-modal {
    display: none;
    position: fixed;
    z-index: 10;
    padding-top: 100px;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 80%;
    overflow: auto;
    background-color: black;
}

/* Modal Content */
.gallery-modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
}

/* The Close Button */
.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

/* Hide the slides by default */
.mySlides {
    display: none;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* Caption text */
.caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
}

img.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    opacity: 1;
}

img.hover-shadow {
    transition: 0.3s;
}

.hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
