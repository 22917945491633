html, .font-sans {
    font-family: "Poppins", sans-serif !important;
}

// Purple buttons
.bg-gray-800 {
    background-color: rgb(174, 173, 240) !important;
    border-radius: 2rem !important;
}

// Grey buttons
.border-gray-300 {
    border-radius: 2rem !important;
    border-color: rgb(188, 182, 218) !important;
    border-width: 2px !important;
}
.modal.p-6.bg-white.rounded-xl.shadow-lg.pointer-events-auto {
    display: block;
    margin-top: 30%;
    position: inherit !important;
}
