// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

// Icons
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-vue/dist/bootstrap-vue.css";

@import "components/chat";
@import "components/billing";
@import "components/search";

//@import "multi_item_carousel";
@import "multiple_item_carousel";
// Fonts
@import "fonts";

@import "custom";


@import "~dropzone/src/dropzone";

@import "searchbar";

@import "~select2/src/scss/core";
@import "~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4";
@import "toggle";

@import "~flatpickr/dist/flatpickr.min.css";
@import "~flatpickr/dist/themes/material_green.css";

@import "media-library-pro-styles";
